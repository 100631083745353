import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ContentBlock from "../../components/shared/sub/contentBlock"

import PrivacyStyle from "./privacyContentStyle"

const PrivacyPolicyContent = () => {
  const {
    sanityPrivacyPolicy: { policyContent },
  } = useStaticQuery(graphql`
    query {
      sanityPrivacyPolicy {
        policyContent {
          _rawContent
        }
      }
    }
  `)

  return (
    <PrivacyStyle>
      <div className="container content">
        <div className="main">
          <h1>Privacy Policy</h1>
          <ContentBlock blocks={policyContent._rawContent} />
        </div>
      </div>
    </PrivacyStyle>
  )
}

export default PrivacyPolicyContent
