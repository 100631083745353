import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO, { seoDataFormat } from "../shared/seo"

const PrivacySEO = () => {
  const {
    sanityPrivacyPolicy: { seo },
  } = useStaticQuery(graphql`
    query {
      sanityPrivacyPolicy {
        seo {
          pageTitle
          pageDescription
          pageKeyWords
          ogImage {
            asset {
              url
            }
          }
          slug {
            current
          }
        }
      }
    }
  `)

  const seoData = seoDataFormat(seo)

  return <SEO {...seoData} />
}

export default PrivacySEO
