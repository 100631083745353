import React from "react"
import PrivacySEO from "../components/privacy/privacy"

import PrivacyContent from "../components/privacy/privacyContent"

const Policies = () => {
  return (
    <div className="subpage-container">
      <PrivacySEO />
      <PrivacyContent />
    </div>
  )
}

export default Policies
